import React, { useEffect, useRef, useState } from "react";
import ScrollReveal from "scrollreveal";
import { Helmet } from "react-helmet";

const ContactUs = ({ csrfToken }) => {
  const [formData, setFormData] = useState({
    your_name: "",
    your_number: "",
    your_email: "",
    message: "",
  });
  const [showConfirmation, setShowConfirmation] = useState(false);
  const ref = useRef();

  useEffect(() => {
    ScrollReveal().reveal(ref.current, {
      delay: 200,
      duration: 400,
      scale: 0.85,
      reset: true,
    });
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(
        "https://msigana.com/api/customermessage/create/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": csrfToken,
          },
          body: JSON.stringify(formData),
        }
      );

      if (response.ok) {
        setShowConfirmation(true);
        setFormData({
          your_name: "",
          your_number: "",
          your_email: "",
          message: "",
        });
      } else {
        console.error("Failed to submit form:", response.statusText);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  return (
    <>
      <Helmet>
        {" "}
        <title>Contact Us | Msigana.com</title>
        <meta name="theme-color" content="#000000" />
        <meta
          name="description"
          content="Connect with us effortlessly! Reach out for tailored software solutions, expert consultations, and seamless support.Let's collaborate for your digital success!"
        />
        <meta
          name="keywords"
          content="software development, Digital Marketing,Advisory/Consulting,Training,Business Optimization, web development,ERP, mobile app development, UI/UX design"
        />
        <meta name="author" content="Msigana.com" />
      </Helmet>
      <div
        ref={ref}
        className="text-center bg-center flex justify-center z-10 "
        style={{ backgroundImage: 'url("/images/contact.jpg")' }}
      >
        <div className="w-full md:4/5 2xl:w-2/3 p-8 bg-white z-20 md:mt-10 mb-12 md:mb-16 mx-4 rounded-lg shadow-full">
          <h1 className="text-green-600 font-prompt text-3xl md:text-4xl md:py-8 font-extrabold">
            Get in Touch!
          </h1>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-8 ">
            <div className="text-left  border-slate-500  p-4 shadow-lg">
              <div className="mt-4">
                <p className="pb-0 md:pb-16">
                  <a href="tel:+251995030321">
                    <i className="fas fa-phone-alt mr-4 md:mr-6 text-red-500 text-2xl "></i>
                    <span className="text-2xl md:text-xl text-bold">
                      +251995030321
                    </span>
                  </a>
                </p>
                <p className="pb-8 md:pb-16 hidden md:block">
                  <a
                    href="https://maps.app.goo.gl/NWMKjvqzUU6uiynP7"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center text-black hover:underline"
                  >
                    <i className="fas fa-map-marker-alt mr-4 md:mr-6 text-red-500 text-2xl"></i>
                    Airport Rd, Addis Ababa Ethiopia
                  </a>
                </p>

                <p className="pb-8 hidden md:block">
                  <i className="fas fa-envelope mr-4 md:mr-6 text-red-500 text-2xl"></i>
                  <a href="mailto:info.msiganadigitalsolutions@gmail.com">
                    info.msiganadigitalsolutions@gmail.com
                  </a>
                </p>

                <p className="pb-8 hidden md:block">
                  <i className="fa-solid fa-clock mr-4 md:mr-6 text-red-500 text-2xl"></i>
                  Monday - Saturday: 8am - 11pm
                </p>
              </div>
            </div>
            <div className=" border-slate-500  p-4 shadow-lg">
              {showConfirmation && (
                <div
                  id="message"
                  className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative"
                  role="alert"
                >
                  <strong className="font-bold">Thank you!</strong>
                  <span className="block sm:inline">
                    We'll get back to you soon!
                  </span>
                </div>
              )}
              <form onSubmit={handleSubmit} className="space-y-4">
                <input
                  type="hidden"
                  name="csrfmiddlewaretoken"
                  value="{ csrfToken }"
                />
                <input
                  type="text"
                  name="your_name"
                  placeholder="Name"
                  value={formData.your_name}
                  onChange={handleChange}
                  className="w-full text-black px-4 py-2 border rounded-lg focus:outline-none focus:border-blue-500"
                />
                <input
                  type="text"
                  name="your_number"
                  placeholder="Number"
                  value={formData.your_number}
                  onChange={handleChange}
                  className="w-full text-black px-4 py-2 border rounded-lg focus:outline-none focus:border-blue-500"
                />
                <input
                  type="email"
                  name="your_email"
                  placeholder="Email"
                  value={formData.your_email}
                  onChange={handleChange}
                  className="w-full text-black px-4 py-2 border rounded-lg focus:outline-none focus:border-blue-500"
                />
                <textarea
                  name="message"
                  placeholder="Message"
                  rows="5"
                  value={formData.message}
                  onChange={handleChange}
                  className="w-full text-black px-4 py-2 border rounded-lg focus:outline-none focus:border-blue-500"
                ></textarea>
                <input
                  type="submit"
                  value="Send"
                  className="w-full py-2 bg-blue-500 text-white font-semibold rounded-lg hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-300"
                />
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUs;
