import React, { useRef } from "react";
import { motion, useMotionValue, useTransform } from "framer-motion";

const HorizontalScroll = ({ post }) => {
  const containerRef = useRef(null);
  const dragX = useMotionValue(0);

  const handleDragConstraints = () => {
    if (containerRef.current) {
      const containerWidth = containerRef.current.clientWidth;
      const contentWidth = containerRef.current.scrollWidth;
      return { left: -contentWidth + containerWidth, right: 0 };
    }
  };

  const opacity = useTransform(dragX, [-500, 0], [0.5, 1]);

  return (
    <div className="horizontal-scroll" ref={containerRef}>
      <motion.div
        className="scroll-content"
        style={{ x: dragX, opacity }}
        drag="x"
        dragConstraints={handleDragConstraints()}
        dragElastic={0.1}
      >
        <div className="max-w-sm w-full lg:max-w-full lg:flex mb-12 md:mb-16">
          <div
            className="h-48 lg:h-auto lg:w-48 flex-none bg-cover rounded-t lg:rounded-t-none lg:rounded-l text-center overflow-hidden"
            style={{ backgroundImage: `url('${post.image}')` }}
          ></div>
          <div className="border-r border-b border-l border-gray-400 lg:border-l-0 lg:border-t lg:border-gray-400 bg-white rounded-b lg:rounded-b-none lg:rounded-r p-4 flex flex-col justify-between leading-normal">
            <div className="mb-8">
              <p className="text-sm text-gray-600 flex items-center">
                <svg
                  className="fill-current text-gray-500 w-3 h-3 mr-2"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M4 8V6a6 6 0 1 1 12 0v2h1a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-8c0-1.1.9-2 2-2h1zm5 6.73V17h2v-2.27a2 2 0 1 0-2 0zM7 6v2h6V6a3 3 0 0 0-6 0z" />
                </svg>
                Members only
              </p>
              <div className="text-gray-900 font-bold text-xl mb-2">
                {post.title}
              </div>
              <p
                className="text-gray-700 text-base"
                dangerouslySetInnerHTML={{ __html: post.short_description }}
              ></p>
            </div>
            <div className="flex items-center">
              <img
                src={post.image}
                alt={post.title}
                className="w-10 h-10 rounded-full mr-4"
              />
              <div className="text-sm">
                <p className="text-gray-900 leading-none">Jonathan Reinink</p>
                <p className="text-gray-600">Aug 18</p>
              </div>
            </div>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default HorizontalScroll;
