import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import ScrollReveal from "scrollreveal";

function MainBanner({ post, customClass }) {
  const textRef = useRef(); // Ref for the text content

  useEffect(() => {
    if (!post) return; // Check if post is undefined
    ScrollReveal().reveal(textRef.current, {
      delay: 200,
      duration: 400,
      scale: 0.85,
      reset: true,
    });
  }, [post]);

  if (!post) return null; // Return null if post is undefined

  const backgroundImageStyle = {
    backgroundImage: `url(${post.image})`, // Update to use post.image directly
  };

  return (
    <div
      key={post.slug}
      className={`bg-cover bg-no-repeat object-cover bg-center py-12 xl:py-48 2xl:py-64 relative  ${customClass}`}
      style={backgroundImageStyle}
    >
      <div ref={textRef} className="text-content">
        <h1 className="text-3xl md:text-5xl xl:text-6xl text-white font-medium md:mb-4 px-3 font-mono">
          {post.title}
        </h1>
        <p
          className="text-base md:text-xl text-white leading-6 md:ml-8 text-start md:text-center px-3 lg:px-32 2xl:px-64 py-2 pt-4 "
          dangerouslySetInnerHTML={{ __html: post.content }}
        />
        <div className=" mt-1 md:mt-6  text-center">
          <Link
            to={`contact-us`}
            className="bg-blue-700 border border-blue-600 text-white text-lg  px-4 py-3 font-medium rounded-md uppercase hover:bg-white hover:text-blue-800 transition"
          >
            {" "}
            Contact Us / መልክት ያስቀምጡ
          </Link>
        </div>
      </div>
    </div>
  );
}

export default MainBanner;
