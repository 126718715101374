import React, { useState, useEffect } from "react";
import { createRoot } from "react-dom/client"; // Import createRoot from react-dom/client
import { DotLoader } from "react-spinners";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

function Root() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate data loading delay
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <React.StrictMode>
      {loading ? (
        <div className="loading-container">
          <div className="flex justify-center items-center mt-48 md:mt-64">
            <DotLoader color="#123abc" loading={loading} size={50} />
            <DotLoader color="#123abc" loading={loading} size={30} />
            <DotLoader color="#123abc" loading={loading} size={20} />
            <DotLoader color="#123abc" loading={loading} size={10} />
          </div>
        </div>
      ) : (
        <App />
      )}
    </React.StrictMode>
  );
}

// Use createRoot from react-dom/client instead of directly importing from react-dom
createRoot(document.getElementById("root")).render(<Root />);
reportWebVitals();
