import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

function DetailPage() {
  const { slug } = useParams();
  const [post, setPost] = useState(null);
  const [metaDescription, setMetaDescription] = useState("");

  useEffect(() => {
    fetch(`https://msigana.com/api/posts/${slug}`)
      .then((response) => response.json())
      .then((data) => {
        setPost(data);
        // Extract plain text for meta description
        const plainText = data.content.replace(/<[^>]+>/g, "");
        // Limit the meta description to 160 characters
        const trimmedDescription = plainText.substring(0, 160);
        setMetaDescription(trimmedDescription);
      });
  }, [slug]);

  if (!post) return "Loading...";

  return (
    <div>
      <Helmet>
        <title>{post.title} | Msigana.com</title>
        <meta name="theme-color" content="#000000" />
        <meta name="description" content={metaDescription} />
        <meta
          name="keywords"
          content="software development, Digital Marketing,Advisory/Consulting,Training,Business Optimization, web development,ERP, mobile app development, UI/UX design"
        />
        <meta name="author" content="Msigana.com" />
      </Helmet>
      <div className="mt-8 md:mt-16 mb-8  flex justify-center items-center">
        <div className="flex flex-col md:flex-row w-full md:w-3/4 px-4 md:px-0">
          <div className="md:w-1/2 md:pr-8">
            <div className="group-hover:scale-110 pb-8">
              {post.image && (
                <a href={`/posts/${slug}`}>
                  <img
                    src={post.image}
                    alt={post.title}
                    className=" h-max-96 w-full object-cover"
                  />
                </a>
              )}
            </div>
          </div>
          <div className="md:w-1/2">
            <h1 className="text-3xl md:text-2xl lg:text-3xl text-green-600">
              {post.title}
            </h1>
            <p
              className="mt-4 font-prompt font-Marko One font-Oswald text-lg"
              dangerouslySetInnerHTML={{ __html: post.content }}
            ></p>
          </div>
        </div>
      </div>
      <div className="mt-4 ml-8 md:ml-32 mb-3">
        <Link to="/" className="text-blue-500 hover:underline">
          <i className="fa-solid fa-arrow-left text-4xl"></i>
        </Link>
      </div>
    </div>
  );
}

export default DetailPage;
