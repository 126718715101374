// HomePage.jsx
import React from "react";
import CategoryPosts from "./CategoryPosts";
import ContactUs from "./ContactUsPage";
import { Helmet } from "react-helmet";

function HomePage() {
  return (
    <div>
      <Helmet>
        {" "}
        <title>
          Software Development, Consulting, and Training Services - All in One
          Place
        </title>
        <meta name="theme-color" content="#000000" />
        <meta
          name="description"
          content="We provide custom software development services to help businesses achieve their goals. Contact us for web and mobile app development, UI/UX design, and more."
        />
        <meta
          name="keywords"
          content="software development, Digital Marketing,Advisory/Consulting,Training,Business Optimization, web development,ERP, mobile app development, UI/UX design"
        />
        <meta name="author" content="Msigana.com" />
      </Helmet>
      <CategoryPosts category="main" />
      <CategoryPosts category="orange" />
      <CategoryPosts category="boll" />
      <ContactUs csrfToken="{{ csrfToken }}" />
    </div>
  );
}

export default HomePage;
