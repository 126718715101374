import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import ScrollReveal from "scrollreveal";

function OrangePost({ post, customClass }) {
  const ref = useRef();

  useEffect(() => {
    ScrollReveal().reveal(ref.current, {
      delay: 200,
      duration: 400,
      scale: 0.85,
      reset: true,
    });
  }, []);

  return (
    <div
      ref={ref}
      key={post.slug}
      className={`flex flex-col bg-white text-gray-800 rounded-lg shadow-md hover:shadow-lg overflow-hidden m-4  ${customClass}`}
    >
      <div className="flex-shrink-0 h-48 w-full">
        <img
          className="h-full w-full object-cover"
          src={post.image}
          alt={post.title}
        />
      </div>
      <div className="px-6 py-2  pb-4 md:py-4">
        <Link
          to={`/post/${post.slug}`}
          className="text-xl font-semibold mb-2 hover:text-gray-700"
        >
          {post.title}
        </Link>
        <p
          className="text-base text-start"
          dangerouslySetInnerHTML={{ __html: post.short_description }}
        ></p>
      </div>
      <div className="px-6 md:py-4  pb-3 flex items-center justify-between">
        <Link
          to={`/post/${post.slug}`}
          className="px-2 py-2 bg-blue-600 text-white text-xs font-bold uppercase rounded"
        >
          Read More
        </Link>
        <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2">
          {post.title_one}
        </span>
      </div>
    </div>
  );
}

export default OrangePost;
