import React, { useState, useEffect, useRef } from "react";
import ScrollReveal from "scrollreveal";
import OrangePost from "./OrangePost";
import BollPost from "./BollPost";
import MainBanner from "./MainBanner";
import HorizontalScroll from "./HorizontalScroll";
import Blog from "./Blog";
import Services from "./Services";
import AboutPost from "./AboutPost";

function CategoryPosts({ category }) {
  const [posts, setPosts] = useState([]);
  const [scrollX, setScrollX] = useState(0);
  const titleRef = useRef();

  useEffect(() => {
    // Apply ScrollReveal animation to the title element
    ScrollReveal().reveal(titleRef.current, {
      delay: 200,
      duration: 400,
      scale: 0.85,
      reset: true,
    });
  }, []);

  useEffect(() => {
    fetch(`https://msigana.com/api/posts/?category=${category}`)
      .then((response) => response.json())
      .then((data) => setPosts(data));
  }, [category]);

  const handleScroll = (newX) => {
    setScrollX(newX);
  };

  const renderPost = (post) => {
    switch (category) {
      case "orange":
        return (
          <OrangePost key={post.slug} post={post} customClass="orange-post" />
        );
      case "boll":
        return <BollPost key={post.slug} post={post} customClass="boll-post" />;
      case "about":
        return (
          <AboutPost key={post.slug} post={post} customClass="about-post" />
        );
      case "main":
        return (
          <MainBanner key={post.slug} post={post} customClass="main-post" />
        );
      case "blog":
        return <Blog key={post.slug} post={post} customClass="blog" />;
      case "services":
        return <Services key={post.slug} post={post} customClass="services" />;
      case "horizontal":
        return (
          <HorizontalScroll
            key={post.slug}
            post={post}
            customClass="horizontal-post"
            scrollX={scrollX} // Pass scrollX state as a prop
            handleScroll={handleScroll}
          />
        );
      default:
        return null;
    }
  };

  let gridClass = "";

  switch (category) {
    case "orange":
      gridClass = "grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4";
      break;
    case "boll":
      gridClass = "grid grid-cols-1 md:grid-cols-2 gap-0 lg:gap-12";
      break;
    case "about":
      gridClass = "grid grid-cols-1 md:grid-cols-2 gap-0 lg:gap-12";
      break;
    case "blog":
      gridClass =
        "grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-2 md:gap-4 mb-12";
      break;
    case "services":
      gridClass =
        "grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-2 md:gap-4 mb-12";
      break;
    default:
      break;
  }
  const categoryStyles = {
    orange:
      "max-w-screen-md lg:max-w-screen-lg 2xl:max-w-screen-xl text-center mx-auto",
    boll: "max-w-screen-md lg:max-w-screen-lg 2xl:max-w-screen-xl text-center mx-auto",
    head: "max-w-screen-md lg:max-w-screen-lg 2xl:max-w-screen-xl text-center mx-auto",
    main: "md:text-center  text-2xl mx-auto mt-0",
    blog: "max-w-screen-md lg:max-w-screen-lg 2xl:max-w-screen-xl text-center mx-auto",
    services:
      "max-w-screen-md lg:max-w-screen-lg 2xl:max-w-screen-xl text-center mx-auto",
  };

  const containerStyle =
    categoryStyles[category] ||
    "max-w-screen-md lg:max-w-screen-lg 2xl:max-w-screen-xl text-center mx-auto";

  const categoryTitles = {
    orange: {
      title: "Your Bridge to Digital World ",
      className:
        "text-orange-500 text-center px-2 mt-2 md:mt-8 mb-1 md:mb-4 lg:mt-12 text-2xl xl:text-3xl 2xl:text-4xl font-mono",
    },
    boll: {
      title: "Partner to unlock your Digital Potential",
      className:
        "text-blue-500 text-center mt-8 mb-4 md:mb-8 lg:mt-12 text-2xl xl:text-3xl 2xl:text-4xl font-mono",
    },
    about: {
      title: "About us",
      className:
        "text-blue-500 text-center mt-8 mb-4 lg:mt-12 text-2xl xl:text-3xl 2xl:text-4xl font-mono",
    },
    main: {
      title: "",
      className: "hidden",
    },
    blog: {
      title: "Blogs",
      className:
        "text-blue-500 text-center font-bold mt-8 mb-4 lg:mt-12 text-3xl 2xl:text-4xl font-mono",
    },
    services: {
      title: "Services",
      className:
        "text-blue-500 font-bold text-center mt-8 mb-4 lg:mt-12 text-3xl 2xl:text-4xl font-mono",
    },
  };

  const { title, className } = categoryTitles[category] || {
    title: "Default Category",
    className:
      "text-gray-500 text-center mt-8 mb-4 lg:mt-12 text-2xl xl:text-3xl 2xl:text-4xl font-mono",
  };

  return (
    <div className={containerStyle}>
      <h1 ref={titleRef} className={` ${className}`}>
        {title}
      </h1>
      <div className={gridClass}>{posts.map(renderPost)}</div>
    </div>
  );
}

export default CategoryPosts;
