// components/ErrorPage.js

import React from "react";

const ErrorPage = () => {
  return (
    <div className="bg-green-500 text-yellow-100 ">
      <h1 className="text-4xl">500 Internal Server Error</h1>
      <p className="text-4xl">
        We are currently updating the system. Please wait and try again later.
      </p>
    </div>
  );
};

export default ErrorPage;
