import React, { useEffect, useRef } from "react";
import ScrollReveal from "scrollreveal";
import { Link } from "react-router-dom";

function AboutPost({ post }) {
  const ref = useRef();

  useEffect(() => {
    ScrollReveal().reveal(ref.current, {
      delay: 200,
      duration: 400,
      scale: 0.85,
      reset: true,
    });
  }, []);

  return (
    <Link to={`/post/${post.slug}`}>
      <div
        ref={ref}
        key={post.slug}
        className="mx-2 max-w-full flex mb-8 md:mb-12 border shadow-md"
      >
        <div className="w-full flex">
          {/* Image container - 1/3 width on large screens */}
          <div
            className="w-1/3 lg:flex-none bg-cover rounded-t lg:rounded-t-none lg:rounded-l text-center overflow-hidden max-h-60 md:h-64"
            style={{ backgroundImage: `url('${post.image}')` }}
          ></div>
          {/* Content container - 2/3 width on large screens */}
          <div className="w-2/3 md:w-auto bg-white rounded-b lg:rounded-b-none lg:rounded-r pl-2 pt-0 pr-2  md:p-4 md:pb-0 flex flex-col justify-between leading-normal">
            <div className="md:mb-8">
              <div className="text-gray-900 font-bold text-xl mb-2">
                {post.title}
              </div>
              <p
                className="text-gray-700 text-base text-start"
                dangerouslySetInnerHTML={{ __html: post.short_description }}
              ></p>
            </div>
            <div className="flex items-center">
              <a
                href="tel:+251995030321"
                rel="noopener noreferrer"
                className="text-xl md:text-2xl py-3"
              >
                <i className="fas fa-phone-volume w-10 h-10 rounded-full mr-4 text-blue-600 md:text-3xl"></i>
              </a>
              <div className="text-sm">
                <p className="text-gray-900 leading-none">Customer Support</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
}

export default AboutPost;
