import React, { useEffect, useState } from "react";

const ScrollToTopButton = () => {
  const [isButtonVisible, setIsButtonVisible] = useState(false);
  const [lastScrollTop, setLastScrollTop] = useState(0);

  useEffect(() => {
    const toggleButtonVisibility = () => {
      const scrollTop = window.scrollY;
      setIsButtonVisible(scrollTop < lastScrollTop || scrollTop === 0);
      setLastScrollTop(scrollTop);
    };

    window.addEventListener("scroll", toggleButtonVisibility);

    return () => {
      window.removeEventListener("scroll", toggleButtonVisibility);
    };
  }, [lastScrollTop]);

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <button
      onClick={handleScrollToTop}
      className={`fixed bottom-8 right-1 md:right-8 z-50 px-4 py-2 bg-gray-600 text-white font-extrabold rounded-full transition duration-300 ${
        isButtonVisible ? "visible" : "invisible"
      }`}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="w-6 h-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M5 10l7-7m0 0l7 7m-7-7v18"
        />
      </svg>
    </button>
  );
};

export default ScrollToTopButton;
