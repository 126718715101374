import React from "react";
import ScrollToTopButton from "./ScrollToTopButton";
import { Link } from "react-router-dom";
import CallButton from "./CallButton";

const Footer = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      <div className="w-full bg-slate-300 pt-8 md:pt-12 border-gray-100 items-center px-4 sm:px-8 md:px-16 lg:px-8 xl:px-32 2xl:px-64 2xl:max-w-9xl 2xl:mx-auto pb-6 md:pb-8">
        <div className="container">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="space-y-4 md:space-y-8">
              <Link
                to="/"
                href="/"
                className="brand-wrap"
                onClick={scrollToTop}
              >
                <img
                  className="w-64 h-32 object-cover overflow-hidden"
                  src="/images/msigana.png"
                  target="_blank"
                  alt="Msigana logo"
                />
              </Link>
              <p className="text-base">
                Transforming Ideas into Digital Solutions
              </p>
              <div className="space-y-4">
                <div>
                  <span className="text-xl font-medium text-red-600 text-center">
                    Follow us
                  </span>
                </div>
                <div className="flex space-x-6">
                  <a
                    href="https://www.facebook.com/profile.php?id=61555240536245"
                    target="_blank"
                    alt="Facebook Page"
                    className="hover:text-red-600"
                  >
                    <i className="fab fa-facebook-f text-3xl"></i>
                  </a>
                  <a
                    href="https://chat.whatsapp.com/IHrutsNM4GF6SMcegYNHQB"
                    target="_blank"
                    alt="Whatsapp Group"
                    className="hover:text-red-600"
                  >
                    <i
                      className="fab fa-whatsapp text-3xl"
                      aria-hidden="true"
                    ></i>
                  </a>
                  <a
                    href="https://www.linkedin.com/company/msigana-software-designers"
                    target="_blank"
                    alt="Linkedin Page"
                    className="hover:text-red-600"
                  >
                    <i className="fab fa-linkedin text-3xl"></i>
                  </a>
                  <a
                    href="https://t.me/"
                    alt="Telegram Channel"
                    target="_blank"
                    className="hover:text-red-600"
                  >
                    <i className="fab fa-telegram text-3xl"></i>
                  </a>
                </div>
              </div>
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 md:col-span-2">
              <div>
                <h3 className="text-base font-semibold text-red-600 tracking-wider uppercase">
                  Contact Us
                </h3>
                <div className="mt-2 md:mt-4 space-y-2 md:space-y-4">
                  <a
                    href="tel:251995030321"
                    className="text-sm md:text-lg hover:text-gray-900 block"
                  >
                    +2519-9503-0321
                  </a>
                  <Link
                    to="/"
                    className="text-sm md:text-lg hover:text-gray-900 block"
                  >
                    info.msigana.com@gmail.com
                  </Link>
                  <p className="text-sm md:text-lg hover:text-gray-900 block">
                    Airport Rd, Addis Ababa
                  </p>
                </div>
              </div>

              <div>
                <h3 className="text-base font-semibold text-red-600 tracking-wider uppercase">
                  Important Links
                </h3>
                <div className="mt-2 md:mt-4 space-y-2 md:space-y-4">
                  <Link
                    to="/contact-us"
                    className="text-sm md:text-lg hover:text-gray-900 block"
                    onClick={scrollToTop}
                  >
                    Contact Us
                  </Link>
                  <Link
                    to="/services"
                    className="text-sm md:text-lg hover:text-gray-900 block"
                    onClick={scrollToTop}
                  >
                    Services
                  </Link>
                  <Link
                    to="/about-us"
                    className="text-sm md:text-lg hover:text-gray-900 block"
                    onClick={scrollToTop}
                  >
                    About Us
                  </Link>
                  <Link
                    to="/blog"
                    className="text-sm md:text-lg hover:text-gray-900 block"
                    onClick={scrollToTop}
                  >
                    Blog
                  </Link>
                  <Link
                    to="/privacy-policy"
                    className="text-sm md:text-lg hover:text-gray-900 block"
                    onClick={scrollToTop}
                  >
                    Privacy Policy
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-slate-800 px-4 md:px-16 lg:px-16 xl:px-48 w-full">
        <div className="py-4 md:py-4 w-full">
          <div className="container flex items-center justify-between">
            <p className="text-white text-base md:text-lg">
              Copyright &copy;{" "}
              <span className="text-white">{new Date().getFullYear()}</span>
            </p>
            <div className="col-md-8 text-md-center">
              <span className="px-1 md:px-2 text-base md:text-lg text-white">
                Msigana.com
              </span>
            </div>
          </div>
        </div>
      </div>
      <CallButton />
      <ScrollToTopButton />
    </>
  );
};

export default Footer;
