// components/Error502Page.js

import React from "react";

const Error502Page = () => {
  return (
    <div className="bg-red-500 text-white">
      <h1 className="text-4xl">502 Bad Gateway</h1>
      <p className="text-lg">
        The server received an invalid response from an upstream server.
      </p>
    </div>
  );
};

export default Error502Page;
