import React, { useState } from "react";
import { Link } from "react-router-dom";

const Navigation = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  const closeNavbar = () => {
    setIsOpen(false);
  };

  return (
    <nav className="bg-gray-500">
      <div className="flex justify-between items-center px-4 py-0">
        {/* Logo aligned to the left */}
        <div className="flex-shrink-0">
          <Link to="/" className="text-white ">
            <img
              src="/images/msigana.png"
              alt="Logo"
              className="w-32 md:w-48"
            />
          </Link>
        </div>

        {/* Menu items aligned to the right */}
        <div className="hidden sm:block ml-auto">
          <div className="flex space-x-4">
            <Link
              to="/"
              className="text-white hover:bg-gray-400 px-3 py-2 rounded-md text-sm  md:text-xl font-medium"
              onClick={closeNavbar}
            >
              Home
            </Link>
            <Link
              to="about-us/"
              className="text-white hover:bg-gray-400 px-3 py-2 rounded-md text-sm  md:text-xl font-medium"
              onClick={closeNavbar}
            >
              About
            </Link>
            <Link
              to="blog/"
              className="text-white hover:bg-gray-400 px-3 py-2 rounded-md text-sm  md:text-xl font-medium"
              onClick={closeNavbar}
            >
              Blog
            </Link>
            <Link
              to="services/"
              className="text-white hover:bg-gray-400 px-3 py-2 rounded-md text-sm  md:text-xl font-medium"
              onClick={closeNavbar}
            >
              Services
            </Link>
            <Link
              to="contact-us/"
              className="text-white hover:bg-gray-400 px-3 py-2 rounded-md text-sm  md:text-xl font-medium"
              onClick={closeNavbar}
            >
              Contact
            </Link>
          </div>
        </div>

        {/* Mobile menu button */}
        <div className="sm:hidden">
          <button
            onClick={toggleNavbar}
            className="inline-flex items-center justify-center p-2 rounded-md text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
            aria-expanded={isOpen ? "true" : "false"}
          >
            <span className="sr-only">Open main menu</span>
            {isOpen ? (
              <svg
                className="block h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            ) : (
              <svg
                className="block h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16m-7 6h7"
                />
              </svg>
            )}
          </button>
        </div>
      </div>

      {/* Mobile menu */}
      <div
        className={`sm:hidden ${
          isOpen
            ? "fixed inset-0 bg-gray-800 bg-opacity-75 z-50 transition-opacity duration-300 ease-in-out"
            : "hidden"
        }`}
      >
        <div
          className={`fixed inset-y-0 left-0 max-w-xs w-full bg-gray-500 p-8 overflow-y-auto transition-transform duration-300 ease-in-out transform ${
            isOpen ? "translate-x-0" : "-translate-x-full"
          }`}
        >
          <div className="flex items-center justify-between">
            <div>
              <span className="text-white text-lg font-semibold">Menu</span>
            </div>
            <div>
              <button
                onClick={toggleNavbar}
                className="text-gray-400 hover:text-white focus:outline-none focus:text-white"
              >
                <span className="sr-only">Close menu</span>
                <svg
                  className="h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
          </div>
          <div className="mt-6">
            <Link to="/" className="block text-white" onClick={closeNavbar}>
              Home
            </Link>
            <Link
              to="about-us/"
              className="block text-white mt-4"
              onClick={closeNavbar}
            >
              About
            </Link>
            <Link
              to="services/"
              className="block text-white mt-4"
              onClick={closeNavbar}
            >
              Services
            </Link>
            <Link
              to="contact-us/"
              className="block text-white mt-4"
              onClick={closeNavbar}
            >
              Contact
            </Link>
            <Link
              to="blog/"
              className="block text-white mt-4"
              onClick={closeNavbar}
            >
              Blog
            </Link>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navigation;
