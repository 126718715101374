import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navigation from "./components/Navigation";
import HomePage from "./components/HomePage";
import ContactUsPage from "./components/ContactUsPage";
import AboutUsPage from "./components/AboutUsPage";
import DetailPage from "./components/DetailPage";
import Footer from "./components/Footer";
import "@fortawesome/fontawesome-free/css/all.css";
import CategoryPosts from "./components/CategoryPosts";
import PrivacyPolicy from "./components/PrivacyPolicy";
import ErrorPage from "./components/Error500Page";
import Error502Page from "./components/Error502Page";

function App() {
  return (
    <div className="">
      <Router>
        <Navigation />
        <Routes>
          <Route path="/" exact element={<HomePage />} />
          <Route path="/contact-us" element={<ContactUsPage />} />
          <Route path="/about-us" element={<AboutUsPage />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/post/:slug" element={<DetailPage />} />
          <Route
            path="/services"
            element={<CategoryPosts category="services" />}
          />
          <Route path="/blog" element={<CategoryPosts category="blog" />} />
          <Route path="/error" element={<ErrorPage />} />
          <Route path="/error502" element={<Error502Page />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
