import React from "react";
import { Helmet } from "react-helmet";

function PrivacyPolicy() {
  return (
    <div>
      <Helmet>
        {" "}
        <title>Privacy Policy | Msigana.com</title>
        <meta name="theme-color" content="#000000" />
        <meta
          name="description"
          content="Your privacy matters to us! Explore our comprehensive Privacy Policy to understand how we handle your data with care and ensure your peace of mind in every interaction."
        />
        <meta
          name="keywords"
          content="software development, Digital Marketing,Advisory/Consulting,Training,Business Optimization, web development,ERP, mobile app development, UI/UX design"
        />
        <meta name="author" content="Msigana.com" />
      </Helmet>
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-3xl text-blue-500 md:text-4xl font-bold mb-4 text-center">
          Privacy Policy
        </h1>
        <div className="prose prose-lg max-w-2xl mx-auto">
          <p className="mb-2">
            At Msigana.com Company, we are committed to protecting your privacy.
            This Privacy Policy outlines how we collect, use, and safeguard your
            personal information when you visit our website or use our services.
          </p>
          <p className="mb-2">
            We may collect personal information such as your name, email
            address, and contact details when you fill out forms on our website
            or interact with our services. This information is used solely for
            the purpose of providing you with the services you have requested
            and improving your experience with our website.
          </p>
          <p className="mb-2">
            We may also collect non-personal information such as your IP
            address, browser type, and operating system to analyze trends,
            administer the site, track users' movements, and gather demographic
            information for aggregate use.
          </p>
          <p className="mb-2">
            Your personal information will not be sold, exchanged, transferred,
            or given to any other company for any reason whatsoever without your
            consent, other than for the express purpose of delivering the
            requested service.
          </p>
          <p className="mb-2">
            By using our website and services, you consent to the collection and
            use of your personal information as outlined in this Privacy Policy.
            If you have any questions or concerns about our Privacy Policy,
            please contact us.
          </p>
        </div>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
