// AboutUsPage.jsx
import React from "react";
import CategoryPosts from "./CategoryPosts";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
function AboutUsPage() {
  return (
    <>
      <Helmet>
        {" "}
        <title>About Us | Msigana.com</title>
        <meta name="theme-color" content="#000000" />
        <meta
          name="description"
          content="Msigana.com is a technology company based in Addis Ababa, Ethiopia, dedicated to delivering applicable digital solutions to private, NGOs and government institutions."
        />
        <meta
          name="keywords"
          content="software development, Digital Marketing,Advisory/Consulting,Training,Business Optimization, web development,ERP, mobile app development, UI/UX design"
        />
        <meta name="author" content="Msigana.com" />
      </Helmet>
      <div className="flex flex-col items-center justify-center py-2">
        <header className="text-4xl font-bold mb-4 md:mb-8 mt-8 text-blue-500">
          About Us
        </header>
        <section className="max-w-screen-md lg:max-w-screen-lg 2xl:max-w-screen-xl mx-auto px-4 md:px-0">
          <div className="grid grid-cols-1 md:grid-cols-2">
            <div>
              <p className="mb-2 pt-4">
                Msigana.com is a technology company based in Addis Ababa,
                Ethiopia, dedicated to delivering applicable digital solutions
                to private, NGOs and government institutions.
              </p>{" "}
              <p className="mb-2">
                Unlike traditional technology providers in Ethiopia, we go
                beyond mere product development to offer holistic and customised
                services to meet our clients needs encompassing advisory,
                training, business optimization, and product development.
              </p>{" "}
              <p className="mb-2">
                By leveraging our expertise, creativity, and passion for
                innovation, we strive to address the unique challenges faced by
                our clients and empower them to thrive in an increasingly
                digital world.{" "}
              </p>
              <p className="mb-2">
                We value excellence, integrity, and customer satisfaction, and
                we are committed to shaping a brighter future for businesses and
                communities across Ethiopia and beyond.
              </p>
            </div>
            <div className="">
              <img src="/images/aboutus.png" alt="about us" />
            </div>
          </div>
        </section>
      </div>
      <CategoryPosts category="about" />
      <div className="mt-4 ml-8 md:ml-32 mb-3">
        <Link to="/" className="text-blue-500 hover:underline">
          <i className="fa-solid fa-arrow-left text-4xl"></i>
        </Link>
      </div>
    </>
  );
}

export default AboutUsPage;
