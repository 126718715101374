import React from "react";

const CallButton = () => {
  const handlePhoneCall = () => {
    window.location.href = "tel:+251995030321"; // Replace with your phone number
  };

  return (
    <button
      onClick={handlePhoneCall}
      className="fixed bottom-16 right-1 md:right-8 z-50 px-4 py-2 bg-slate-600 text-white font-extrabold rounded-full transition duration-300 visible mb-4"
    >
      <i className="fas fa-phone-volume w-2 md:w-3 h-1 rounded-full mr-4 text-white md:text-3xl"></i>
    </button>
  );
};

export default CallButton;
